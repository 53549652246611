import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  confirm(event) {
    event.preventDefault();
    event.stopPropagation();

    let element = event.target.closest("[data-confirm-text]");
    let bodytext = element.dataset.confirmText;
    let title = element.dataset.confirmTitle;
    let remote = element.dataset.confirmRemote || false;

    modbox.confirm({
      body: bodytext,
      title: title,
      center: true,
      okButton: {
        label: "Ok"
      },
      closeButton: {
        label: "Cancel"
      }
    })
    .then(() => {
      if (remote) {
        this.redirectRemote(element);
      } else {
        this.redirectNormal(element);
      }
    })
    .catch(() => {})
  }

  redirectRemote(element) {
    let url = element.href
    let method = element.dataset.confirmMethod || "GET";

    const fetch_options = {
      method: method
    };

    fetch(url, fetch_options);
  }

  redirectNormal(element) {
    let url = element.href
    let method = element.dataset.confirmMethod || "GET";

    var form = document.createElement("form");
    document.body.appendChild(form);
    form.action = url;

    if (method == "GET") {
      form.method = method;
      form.submit();
    } else {
      form.method = "POST";
      var input = document.createElement("input");
      input.type = "hidden";
      input.name = "_method";
      input.value = method;
      form.appendChild(input);
    }

    form.submit();
  }
}
