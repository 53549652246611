import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["magicWordInput", "protectedItem"];
  static values = {
    magicWord: String
  }

  connect() {
    this.magicWordInputTarget.placeholder = this.magicWordValue;
    this.disableProtectedItem();
  }

  disconnect() {
  }

  checkMagicWord() {
    if (this.magicWordInputTarget.value === this.magicWordValue) {
      this.enableProtectedItem();
    } else {
      this.disableProtectedItem();
    }
  }

  disableProtectedItem() {
    this.protectedItemTarget.disabled = true;
    this.protectedItemTarget.classList.add("disabled");
  }

  enableProtectedItem() {
    this.protectedItemTarget.disabled = false;
    this.protectedItemTarget.classList.remove("disabled");
  }
}
