import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["parent", "input", "activeBar", "status"];

    password_strength_hint() {
        const inputValue = this.inputTarget.value;
        if (inputValue === '') {
            this.connect();
        } else {
            const url = encodeURI( this.inputTarget.getAttribute('data-password-strength-url') + inputValue);
            this.loadResult(url);
        }
    }

    async loadResult(url) {
        fetch(url).then(response => response.json()).then(json => {
            json["password_strength"] === 'weak' ? this.showWeakStatus() : null;
            json["password_strength"] === 'strong' ?this.showStrongStatus() : null;
        });
    }

    showWeakStatus() {
        this.showElement(this.parentTarget);
        this.activeBarTarget.classList.remove('strong');
        this.activeBarTarget.classList.add('weak');
        this.statusTarget.innerHTML = 'Weak password';
    }

    showStrongStatus() {
        this.showElement(this.parentTarget);
        this.activeBarTarget.classList.remove('weak');
        this.activeBarTarget.classList.add('strong');
        this.statusTarget.innerHTML = 'Strong password';
    }

    showElement (element) {
        element.style.display = 'block';
    }
}

