import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sourceSelectbox", "sourceText", "destination"];

  sourceSelectboxChange = () => {
    const editor = this.destinationTarget["data-froala.editor"];
    const sourceContent = this.sourceSelectboxTarget.value;
    const destinationContent = editor.html.get();

    // Don't insert the content if it already exists
    if (destinationContent.indexOf(sourceContent) !== -1) {
      return;
    }

    editor.html.insert("<p>" + sourceContent + "</p>");
  };

  sourceTextCopy(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    const editor = this.destinationTarget["data-froala.editor"];
    const sourceContent = this.sourceTextTarget.innerHTML;

    editor.html.insert("<p>" + sourceContent + "</p>");
  }
}
