import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ["image", "popup", "popupImage"];
  static values = {
    darkestClass: "darkest"
  };

  connect() {
    this.modal = new Modal(this.popupTarget);

    document.addEventListener("hide.bs.modal", () => {
      this.removeDarkestBackdrop();
    });
  }

  showPopup(e) {
    e.preventDefault();

    this.popupImageTarget.src = e.target.src;

    this.modal.show();
    this.setDarkestBackdrop();
  }

  setDarkestBackdrop() {
    document.querySelector(".modal-backdrop").classList.add(this.darkestClassValue);
  }

  removeDarkestBackdrop() {
    document.querySelector(".modal-backdrop").classList.remove(this.darkestClassValue);
  }
}
