import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selector"]

  connect() {
    var that = this;

    this.selectorTargets.forEach(function (selector) {
      selector.addEventListener("change", (elem) => {
        let selectorLevel = elem.target.dataset.level;
        that.selectorChanged(parseInt(selectorLevel));
      });
    });

    // Force the first one to refresh and all others will follow:
    this.setupOptions(1);
  }

  disconnect() {
    this.selectorTargets.forEach(function (selector) {
      selector.removeEventListener("change", (elem) => {
        let selectorLevel = elem.target.dataset.level;
        that.selectorChanged(parseInt(selectorLevel));
      });
    });
  }

  selectorChanged(selectorLevel) {
    let selector = this.getSelector(selectorLevel);
    if (!selector) { return }

    if (this._somethingIsSelected(selector)) {
      // Change contents of lower selector...
      this.enableSelector(selectorLevel + 1);
      this.loadSelectorOptions(selectorLevel + 1);
    } else {
      // ...or hide it.
      this.disableSelector(selectorLevel + 1);
    }

    // So that all others refresh themselves:
    this.selectorChanged(selectorLevel + 1);
  }

  loadSelectorOptions(selectorLevel) {
    let selector = this.getSelector(selectorLevel);
    if (!selector) { return }

    let parentSelector = this.getSelector(selectorLevel - 1);

    let levelOptions = []

    if (selector.dataset.remotePossibleOptionsUrl) {
      if (!parentSelector) {
        throw("Currently remote options can only be used with a child selector.");
      }

      let url = selector.dataset.remotePossibleOptionsUrl;
      let parentIds = Array.from(parentSelector.selectedOptions).map(v => v.value);
      let fullUrl = new URL(url);

      if (parentIds.length > 0) {
        parentIds.forEach(id => fullUrl.searchParams.append("parent_id[]", id));

        fetch(fullUrl)
          .then(response => response.json())
          .then(json => this._populateSelectBox(selector, json))
      }
    } else {
      levelOptions = selector.dataset.possibleOptions;
      var newLevelOptions = levelOptions;

      // If there is a selector above then add only matching subset of options.
      if (parentSelector) {
        var optsArray = JSON.parse(levelOptions)
        var parentArray = this._arrayVal(parentSelector);

        var newLevelOptions = optsArray.filter((option) => {
          return parentArray.includes(String(option.parent_id));
        });
      }

      this._populateSelectBox(selector, newLevelOptions);
    }
  }

  disableSelector(selectorLevel) {
    let selector = this.getSelector(selectorLevel);

    if (selector) {
      var control = selector.tomselect;
      control.clear();
      control.disable();
    }
  }

  enableSelector(selectorLevel) {
    let selector = this.getSelector(selectorLevel);

    if (selector) {
      var control = selector.tomselect;
      control.enable();
    }
  }

  setupOptions(selectorLevel) {
    let selector = this.getSelector(selectorLevel);
    let parentSelector = this.getSelector(selectorLevel - 1);

    if (!selector) { return }

    this.loadSelectorOptions(selectorLevel);

    // Hide if parent is blank
    if (parentSelector && !this._somethingIsSelected(parentSelector)) {
      this.disableSelector(selectorLevel);
    }

    this.setupOptions(selectorLevel + 1);
  }

  // find one of the targets with the expected level data element
  getSelector(selectorLevel) {
    var el = this.selectorTargets.find((elem) => {
      return elem.dataset.level == selectorLevel
    });

    return el;
  }

  // Make sure selector val is always an Array. Important for consistency.
  _arrayVal(selector) {
    let val = selector.tomselect.items;

    if (val) {
      return Array.of(val).flat();
    } else {
      return [];
    }
  }

  _somethingIsSelected(selector) {
    return this._arrayVal(selector).length > 0
  }

  _populateSelectBox(selector, options) {
    // make sure we work with arrays here
    let opts = options;
    if (typeof options == "string") {
      opts = JSON.parse(options);
    }

    // clear the selector
    var control = selector.tomselect;
    control.clear();
    control.clearOptions();

    // add empty option if needed
    if (!selector.multiple && selector.dataset.optional && JSON.parse(selector.dataset.optional)) {
      let blankName = selector.dataset.blankName || "";

      control.addOption({ value: "", text: blankName })
    }

    // go through the options (or its parsed array) and add to selector
    opts.forEach(function (item) {
      control.addOption({ value: item.id, text: item.text })
    });

    control.refreshOptions(false);

    const preselectedIds = selector.dataset.preselectedValue ? JSON.parse(selector.dataset.preselectedValue) : [];
    Array.of(preselectedIds).flat().forEach(id => { control.addItem(id, true) });

    selector.dispatchEvent(new Event("change"));
  };
}
