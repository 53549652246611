import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['editor']
    static values = { url: String }

    connect() {
        if (this.urlValue) {
            setTimeout(() => {this.setBackground(this.urlValue)}, 500)
        }
    }

    setBackground(backgroundUrl) {
       const backgroundContainer = this.editorTarget.querySelector('.fr-view');
       backgroundContainer.style.backgroundImage = `url('${backgroundUrl}')`;
    }
}
