import { Controller } from "@hotwired/stimulus"
import { FancyMessage } from "js/utils/fancy_message.es6";

export default class extends Controller {
  static values = {
    maxSize: Number,
    url: String,
    formFields: String,
    allowedExtensions: Array,
    doneUrl: String
  }

  fileSelected() {
    const file = event.target.files[0];

    if (this.fileValid(file)) {
      this.uploadFile(file);
      event.target.value = ""; // So that user can upload file with the same name several times.
    }
  }

  fileValid(file) {
    if (file.size > this.maxSizeValue) {
      this.displayError("File is too large");
      return false;
    }

    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!this.allowedExtensionsValue.includes(fileExtension)) {
      this.displayError("Wrong file format");
      return false;
    }

    return true;
  }

  uploadFile(file) {
    const formData = this.buildForm(file);
    const xhr = new XMLHttpRequest();

    xhr.open("post", this.urlValue, true);
    xhr.addEventListener("load", this.transferComplete.bind(this, file));
    xhr.addEventListener("error", this.transferFailed.bind(this, file));
    xhr.send(formData);

    this.displayInfo("File upload started");
  }

  buildForm(file) {
    const formFields = JSON.parse(this.formFieldsValue);
    const formData = new FormData();

    Object.entries(formFields).map(([key, value]) => {
      formData.append(key, value);
    });

    const extension = file.name.split('.').pop();
    const cleanedName = `${Date.now()}.${extension}`;
    formData.set("key", formFields["key"].replace("${filename}", cleanedName));

    formData.append("Content-Type", file.type);
    formData.append("X-Requested-With", "xhr");
    formData.append("file", file, cleanedName);

    return formData;
  }

  transferComplete(file, data) {
    const s3Key = data.target.responseXML.querySelector("Key");
    // Extract from AWS response:
    // <?xml version="1.0" encoding="UTF-8"?>
    // <Error><Code>EntityTooLarge</Code><Message>Your proposed upload exceeds the maximum allowed size</Message>...</Error>
    const errorMessage = data.target.responseXML.querySelector("Error Message");

    if (errorMessage) {
      if (this.isTestEnvironment) {
        console.error(data.target.responseXML);
      }

      this.displayError(`Upload failed, please try again (${errorMessage.textContent})`);
    } else {
      this.displaySuccess("File upload complete");

      if (this.doneUrlValue) {
        const params = new URLSearchParams({ s3_key: s3Key.textContent, original_filename: file.name });
        const fullUrl = this.doneUrlValue + "?" + params;

        fetch(fullUrl, { method: 'POST' });
      }
    }
  }

  transferFailed(file, data) {
    this.displayError("Upload failed, please try again");
  }

  displayError(message) {
    new FancyMessage('error', message).display();
  }

  displayInfo(message) {
    new FancyMessage('info', message).display();
  }

  displaySuccess(message) {
    new FancyMessage('success', message).display();
  }

  get isTestEnvironment() {
    return document.head.querySelector("meta[name=rails-env]").content === "test"
  }
}
