import { Controller } from "@hotwired/stimulus";
import Swiper, { Navigation } from "swiper/swiper-bundle";
import { FancyMessage } from "js/utils/fancy_message.es6";
import { createConsumer } from "@rails/actioncable";

export default class extends Controller {
  static targets = [
    "container", "recordStream",
    "prevButton", "nextButton", "formOkButton", "formCancelButton", "totalPages"
  ];

  static values = {
    reviewerId: Number,
    reviewerName: String,
    moreAchievements: String,
    removeText: String,
    removeTitle: String
  };

  ARROW_LEFT_KEYCODE = 37;
  ARROW_RIGHT_KEYCODE = 39;
  ENTER_KEYCODE = 13;
  BACKSPACE_KEYCODE = 8;

  connect() {
    this.setupChannel();
    this.setupSlider();

    document.addEventListener("turbo:submit-end", this.handleFormSubmit.bind(this));
    document.addEventListener("keydown", this.handleKeyDown.bind(this));
    this.recordStreamTarget.addEventListener("new_batch_loaded", (e) => {
      this.updateTotalPages(e.detail.streamSize);
      this.swiper.update();

      // delayed preload mostly for the situation where only one achievement is available
      setTimeout(() => {
        this.preload();
      }, 1000);
    });

    this.presencePulse = setInterval(() => {
      this.sendReviewStartedMessage(this.activeScreen.dataset.recordStreamRecordId);
    }, 10000);
  }

  disconnect() {
    clearInterval(this.presencePulse);
    document.removeEventListener("turbo:submit-end", this.handleFormSubmit.bind(this));
    document.removeEventListener("keydown", this.handleKeyDown.bind(this));
  }

  setupChannel() {
    const consumer = createConsumer();
    const that = this;

    this.reviewChannel = consumer.subscriptions.create({ channel: "AchievementReview::CableChannel" }, {
      received(data) {
        if (data.type == "current_reviewers") {
          that.updateCurrentReviewers(data);
        } else if (data.type == "review_finished") {
          that.handleRemoteReviewFinished(data);
        }
      }
    });
  }

  handleFormSubmit(e) {
    const success = e.detail.formSubmission.result.fetchResponse.response.headers.get("X-Review-Success") === "true";
    const exception = e.detail.formSubmission.result.fetchResponse.response.headers.get("X-Review-Exception-Message");
    const form = e.detail.formSubmission.formElement;
    const reviewAction = e.detail.formSubmission.body.get("review_action")
    const screen = form.closest("turbo-frame.swiper-slide");
    let advanceSlider = true;

    form.querySelector(".buttons").classList.remove("d-none");
    form.querySelector(".processing").classList.add("d-none");
    form.dataset.submitting = false;

    if (reviewAction == "comment") {
      advanceSlider = false;
    }

    if(!success && exception) {
      this.removeSlide(screen);
      new FancyMessage('error', exception).display();
    }

    if (success && advanceSlider) {
      this.removeSlide(screen);
    }
  }

  handleRemoteReviewFinished(data) {
    const { reviewer_id: reviewerId, achievement_id: achievementId, reviewer_name: reviewerName } = data;

    if (reviewerId == this.reviewerIdValue) {
      return;
    }

    const achievementScreen = document.getElementById(`achievement_${achievementId}`);

    if (achievementScreen) {
      this.remoteReviewSlide(achievementScreen, reviewerName);
    }
  }

  removeSlide(screen) {
    if (!screen) {
      return;
    }

    this.sendReviewSkippedMessage(screen.dataset.recordStreamRecordId);

    this.preload();
    this.swiper.removeSlide(this.screenIndex(screen));
    this.decrementTotalPages();
    this.swiper.update();

    if (this.activeScreen) {
      this.sendReviewStartedMessage(this.activeScreen.dataset.recordStreamRecordId);
    }
  }

  setupSlider() {
    Swiper.use([Navigation]);

    this.swiper = new Swiper(this.containerTarget, {
      slidesPerView: 1,
      spaceBetween: 24,
      centeredSlides: true,
      allowTouchMove: false,
      pagination: {
        el: ".swiper-pagination .current-page",
        type: "custom",
        renderCustom: (swiper, current, total) => {
          const maxAchievements = parseInt(this.totalPagesTarget.innerText, 10);
          if (current > maxAchievements) {
            return maxAchievements;
          } else {
            return current;
          }
        }
      },
      navigation: {
        nextEl: this.nextButtonTarget,
        prevEl: this.prevButtonTarget
      },
      on: {
        init: () => {
          setTimeout(() => {
            this.sendReviewStartedMessage(this.activeScreen.dataset.recordStreamRecordId);
          }, 5000);
        },
        activeIndexChange: () => {
          const prevSlide = this.swiper.slides[this.swiper.previousIndex];

          if (prevSlide) {
            this.removeRemoteReviewedSlide(prevSlide);
            this.sendReviewSkippedMessage(prevSlide.dataset.recordStreamRecordId);
          }

          this.sendReviewStartedMessage(this.activeScreen.dataset.recordStreamRecordId);
        }
      }
    });

    this.swiper.detachEvents();
  }

  sendReviewStartedMessage(id) {
    this.reviewChannel.send({
      type: "review_started",
      reviewer_name: this.reviewerNameValue,
      achievement_id: id
    });
  }

  sendReviewSkippedMessage(id) {
    this.reviewChannel.send({
      type: "review_skipped",
      reviewer_name: this.reviewerNameValue,
      achievement_id: id
    });
  }

  updateCurrentReviewers(data) {
    const achievementId = data.achievement_id;
    const reviewers = data.reviewer_names.filter(reviewer => reviewer !== this.reviewerNameValue);

    const achievementScreen = document.getElementById(`achievement_${achievementId}`);

    if (achievementScreen) {
      const reviewersText = reviewers.join(", ");
      achievementScreen.querySelector(".current-reviewers").innerText = reviewersText;

      if (reviewers.length > 0) {
        achievementScreen.querySelector(".being-reviewed").classList.remove("d-none");
      } else {
        achievementScreen.querySelector(".being-reviewed").classList.add("d-none");
      }
    }
  }

  remoteReviewSlide(screen, reviewerName) {
    if (!screen) {
      return;
    }

    // if it's currently active index, disable the form
    if (screen == this.activeScreen) {
      const form = screen.querySelector("form.new_achievement_reviewal");

      form.querySelector(".form-status").setAttribute("disabled", true);
      screen.querySelector(".just-reviewed").classList.remove("d-none");
      screen.querySelector(".just-reviewed-by").innerText = reviewerName;
    } else {
      // otherwise, remove the slide
      this.removeSlide(screen);
    }
  }

  // remove slide that has been reviewed by someone else;
  removeRemoteReviewedSlide(screen) {
    if (screen.querySelector("form .form-status:disabled")) {
      this.removeSlide(screen);
    }
  }

  handleNextButtonClick(e) {
    e.preventDefault();
    e.stopPropagation();

    this.preload();
  }

  screenIndex(screen) {
    return this.swiper.slides.indexOf(screen);
  }

  get activeScreen() {
    return this.swiper.slides[this.swiper.activeIndex];
  }

  updateTotalPages(streamSize) {
    const totalPages = parseInt(this.totalPagesTarget.innerText, 10);

    if (~~streamSize == totalPages) {
      return;
    }

    this.totalPagesTarget.innerText = streamSize;

    this.highlightCounter();
  }

  highlightCounter() {
    this.totalPagesTarget.classList.add("bg-warning");
    setTimeout(() => {
      this.totalPagesTarget.classList.remove("bg-warning");
    }, 250);
  };

  decrementTotalPages() {
    const totalPages = parseInt(this.totalPagesTarget.innerText, 10);
    this.totalPagesTarget.innerText = totalPages - 1;
    this.highlightCounter();
  }

  preload() {
    if (this.nearEnd() && !this.streamIsDry()) {
      this.recordStreamTarget.dispatchEvent(new Event("next_batch_wanted"));
    }
  }

  nearEnd() {
    if (this.swiper.isEnd) {
      return true;
    }

    if (this.showingSecondLastScreen()) {
      return true;
    }
  }

  showingSecondLastScreen() {
    return (this.swiper.slides.length - this.swiper.activeIndex <= 3);
  }

  streamIsDry() {
    return this.recordStreamTarget.dataset.dry;
  }

  submit(e, action) {
    e.preventDefault();

    const form = e.target.closest("form.new_achievement_reviewal");

    if (form.querySelector(".form-status:disabled")) {
      return false;
    }

    if (form.dataset.submitting) {
      return false;
    }

    form.dataset.submitting = true;

    form.querySelector("[name='review_action']").value = action;
    form.requestSubmit();

    form.querySelector(".buttons").classList.add("d-none");
    form.querySelector(".processing").classList.remove("d-none");
  }

  remove(e) {
    modbox.confirm({
      body: this.removeTextValue,
      title: this.removeTitleValue,
      center: true,
      okButton: {
        label: "Ok"
      },
      closeButton: {
        label: "Cancel"
      }
    })
      .then(() => {
        this.submit(e, "remove");
      })
      .catch(() => { });
  }

  comment(e) {
    this.submit(e, "comment");
  }

  pause(e) {
    this.submit(e, "suspend");
  }

  approve(e) {
    this.submit(e, "approve");
  }

  handleKeyDown(event) {
    if (event.shiftKey) {
      switch (event.which) {
        case this.ENTER_KEYCODE:
          let approveButton = this.activeScreen.querySelector("form.new_achievement_reviewal button.approve");
          approveButton.dispatchEvent(new Event("click"));
          break;

        case this.BACKSPACE_KEYCODE:
          let pauseButton = this.activeScreen.querySelector("form.new_achievement_reviewal button.pause");
          pauseButton.dispatchEvent(new Event("click"));
          break;

        case this.ARROW_LEFT_KEYCODE:
          event.preventDefault();
          this.prevButtonTarget.dispatchEvent(new Event("click"));
          break;

        case this.ARROW_RIGHT_KEYCODE:
          event.preventDefault();
          this.nextButtonTarget.dispatchEvent(new Event("click"));
          break;
      }
    }
  }
}
