import { Controller } from "@hotwired/stimulus"
import { Collapse } from "bootstrap"

export default class extends Controller {
  static targets = ["cards"];

  handleClick(e) {
    if (!e.target.closest("[data-prevent-collapse]")) {
      this.collapseElement(e.currentTarget);
      this.collapseElement(document.querySelector(e.currentTarget.dataset.bsTarget));

      // Record last_clicked_mission and last_clicked_level
      this.record(e);

      // Update the current URL so that it remembers upon refresh
      this.updateCurrentURL(e);

      // Update the card link
      this.updateCardsLink();
    }
  }

  collapseElement(el) {
    let collapse = Collapse.getInstance(el) || new Collapse(el);
    collapse.toggle();
  }

  record(e) {
    let gameplay = {};
    gameplay[e.params.attr] = e.params.attrId;

    const meta_element = document.head.querySelector(`meta[name="csrf-token"]`);
    const csrf_token = meta_element ? meta_element.getAttribute("content") : null;

    const url = e.params.url;

    if (!url) {
      return;
    }

    const fetch_options = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf_token
      },
      method: "PATCH",
      body: JSON.stringify({
        gameplay: gameplay
      })
    };

    fetch(url, fetch_options);
  }

  updateCurrentURL(e) {
    let url = new URL(window.location.href);
    url.searchParams.delete("mission_id");
    url.searchParams.delete("level_id");

    if (e.params.attr == "last_clicked_mission_id") {
      let levelContainer = e.target.closest(".list-item.level");
      let levelId = levelContainer.getAttribute("id").split("_")[1];

      url.searchParams.append("mission_id", e.params.attrId);
      url.searchParams.append("level_id", levelId);
    } else if (e.params.attr == "last_clicked_level_id") {
      url.searchParams.append("level_id", e.params.attrId);
    }

    window.history.replaceState(null, "", url.href);
  }

  updateCardsLink() {
    if (!this.hasCardsTarget) {
      return;
    }

    let currentUrl = new URL(window.location.href);
    let missionId = currentUrl.searchParams.get("mission_id");
    let levelId = currentUrl.searchParams.get("level_id");

    let cardUrl = new URL(this.cardsTarget.href);
    let playerPath = cardUrl.pathname.replace(/\/(mission|level)\/\d+$/, "");
    if (missionId) {
      cardUrl.pathname = `${playerPath}/mission/${missionId}`;
    } else if (levelId) {
      cardUrl.pathname = `${playerPath}/level/${levelId}`;
    }
    this.cardsTarget.href = cardUrl.href;
  }
}
