import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "key" ];

  generate(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    let input = this.keyTarget;
    let click = new Event("change");
    let pwdChars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let pwdLen = 16;
    let randPassword = Array(pwdLen).fill(pwdChars).map(function(x) { return x[Math.floor(Math.random() * x.length)]; }).join("");

    input.value = randPassword;
    input.dispatchEvent(click);
  }
}
