import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["inputGroup", "checkbox", "spinner", "errorMessage"]
  static values = {
    checkedCallbackUrl: String,
    uncheckedCallbackUrl: String,
    checkedCallbackMethod: { type: String, default: "POST" },
    uncheckedCallbackMethod: { type: String, default: "DELETE" }
  }

  changed(event) {
    this.hideErrorMessage();
    this.hideInputGroup();
    this.showSpinner();

    let operation = null;

    if (this.checkboxTarget.checked) {
      operation = this.sendCheckedCallback.bind(this);
    } else {
      operation = this.sendUncheckedCallback.bind(this);
    }

    operation().then((json) => {
      this.hideSpinner();
      this.showInputGroup();

      if (json.error) {
        this.showErrorMessage(json.error);
      }
    })
  }

  sendCheckedCallback() {
    return this.sendCallback(this.checkedCallbackUrlValue, this.checkedCallbackMethodValue)
      .then(async (json) => {
        if (json.error) {
          // Checkbox remains unchecked on failure
          this.checkboxTarget.checked = false;
        }
        return json;
      })
  }

  sendUncheckedCallback() {
    return this.sendCallback(this.uncheckedCallbackUrlValue, this.uncheckedCallbackMethodValue)
      .then(async (json) => {
        if (json.error) {
          // Checkbox remains checked on failure
          this.checkboxTarget.checked = true;
        }
        return json;
      })
  }

  showInputGroup() {
    this.inputGroupTarget.classList.remove("d-none");
  }

  hideInputGroup() {
    this.inputGroupTarget.classList.add("d-none");
  }

  showSpinner() {
    this.spinnerTarget.classList.remove("d-none");
  }

  hideSpinner() {
    this.spinnerTarget.classList.add("d-none");
  }

  showErrorMessage(message) {
    this.errorMessageTarget.innerHTML = message;
    this.errorMessageTarget.classList.remove("d-none");
  }

  hideErrorMessage() {
    this.errorMessageTarget.classList.add("d-none");
  }

  sendCallback(url, method) {
    if (!url) {
      return;
    }

    const meta_element = document.head.querySelector(`meta[name="csrf-token"]`);
    const csrf_token = meta_element ? meta_element.getAttribute("content") : null;

    const fetchOptions = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf_token
      },
      method: method
    };

    return fetch(url, fetchOptions).then(async (response) => {
      const json = await response.json();
      return json;
    })
  }
}
