import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    interceptPath: String
  }

  initialize() {
    let self = this;

    this.element.addEventListener("click", function(event) {
      if (Rewardful.referral) {
        let el = event.target;

        if (el.tagName === "A" && !el.isContentEditable && el.pathname.endsWith(self.interceptPathValue)) {
          let seperator = (el.href.indexOf('?') != -1) ? '&' : '?';
          let referralParam = `referral=${Rewardful.referral}`;

          el.setAttribute("href", `${el.href}${seperator}${referralParam}`);
        }
      }
    }, true);
  }
}
