import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form" ];

  connect() {
    this.formTarget.addEventListener("submit", () => {
      this.formSubmitting = true;
    });
    window.onbeforeunload = () => this.warnBeforeLeaving(this.formSubmitting);
  }

  // Display a default warning message before leaving the page, unless the form is being submitted.
  warnBeforeLeaving(submitting) {
    if (submitting) {
      return;
    }

    return "";
  }
}
