import { Controller } from "@hotwired/stimulus"
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export default class extends Controller {
  toggle() {
    const hamburger = document.querySelector('#hamburger')
    const navMobile = document.querySelector('#nav_mobile')

    hamburger.classList.toggle('is-open');
    navMobile.classList.toggle('is-open');

    ((navMobile.classList.contains('is-open'))
      ? disableBodyScroll(navMobile)
      : enableBodyScroll(navMobile))
  }
}
