import ApexCharts from "apexcharts"
import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["chart"]
  static values = {
    title: String,
    height: Number,
    subtitle: Array,
    color: String,
    series: Array
  }

  connect() {
    const chart = new ApexCharts(this.chartTarget, {
      series: this.seriesValue,
      chart: {
        height: this.heightValue,
        type: 'bar'
      },
      plotOptions: {
        bar: {
          horizontal: true,
        }
      },
      colors: [this.colorValue],
      dataLabels: {
        dropShadow: {
          enabled: true,
        },
      },
      title: {
        text: this.titleValue,
        align: 'center',
      },
      subtitle: {
        text: this.subtitleValue,
        align: 'center',
        offsetY: 20
      },
      noData: {
        text: "No data",
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: ['Current', 'Previous'],
        markers: {
          fillColors: [this.colorValue, '#666']
        }
      }
    })

    chart.render()
  }
}
