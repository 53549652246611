import { Controller } from "@hotwired/stimulus"
import { Sortable, MultiDrag } from "sortablejs"

Sortable.mount(new MultiDrag());

export default class extends Controller {
  static values = {
    doneUrl: String,
    draggable: String,
    listType: String,
    listName: String,
  }

  connect() {
    const parent = document.querySelector('body')

    new Sortable(this.element, {
        scroll: true,
        forceAutoScrollFallback: true,
        scrollSpeed: 100,
        scrollSensitivity: 100,
        dataIdAttr: "data-sortable-item-id",
        handle: ".sortable-handle",
        group: this.listTypeValue,
        multiDrag: true,
        draggable: this.draggableValue,
        onStart: () => {
          parent.classList.add('cursor-grapping')
        },
        onEnd: (e) => {
          parent.classList.remove('cursor-grapping')
          this.itemsDropped(e)
        }
      }
    )
  }

  disconnect() {
    // Do not destroy Sortable instance here! It causes weird bug.
    // When you drag & drop, DOM is being changed and Stimulus constantly calls connect/disconnect,
    // corrupting Sortable instance!
  }

  itemsDropped(evt) {
    const formData = new FormData();
    const sourceList = Sortable.get(evt.from);
    const destinationList = Sortable.get(evt.to);

    formData.append("source", evt.from.dataset.sortableListNameValue);
    formData.append("destination", evt.to.dataset.sortableListNameValue);

    // Sometimes there's only one, sometimes there's more :/
    let movedItems = new Set();
    evt.items.forEach(item => movedItems.add(item.dataset.sortableItemId));
    movedItems.add(evt.item.dataset.sortableItemId);

    sourceList.toArray().forEach(item => formData.append('source_items[]', item));
    destinationList.toArray().forEach(item => formData.append('destination_items[]', item));
    movedItems.forEach(item => formData.append('moved_items[]', item));

    fetch(this.doneUrlValue, { method: 'POST', body: formData });
  }
}
