import { Controller } from "@hotwired/stimulus"
import { Tooltip } from "bootstrap"

export default class extends Controller {
  connect() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))

    this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl, { placement: "top" })
    })
  }

  disconnect() {
    this.tooltipList.map(function(tooltip) {
      // Do not use .dispose() here!
      // https://github.com/twbs/bootstrap/issues/25519
      tooltip.hide();
      tooltip.disable();
    });
  }
}
