import ApexCharts from "apexcharts"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "chart" ]
  static values = {
    title: String,
    series: Array,
    categories: Array
  }

  connect() {
    const chart = new ApexCharts(this.chartTarget, {
      chart: {
        type: "area",
        width: "100%",
        height: 200,
        sparkline: {
          enabled: true
        }
      },
      stroke: {
        curve: "smooth",
        colors: "#0A7DAD",
      },
      fill: {
        gradient: {
          shade: "dark",
          shadeIntensity: 0,
          gradientToColors: ["#0A7DAD", "#0A7DAD"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [100, 100, 100]
        }
      },
      stacked: true,
      series: [{
        name: this.titleValue,
        data: this.seriesValue
      }],
      xaxis: {
        type: "category",
        categories: this.categoriesValue
      },
      dataLabels: {
        enabled: false,
      },
      defer: false
    })

    chart.render()
  }
}
