import { Controller } from "@hotwired/stimulus"
import { Dropdown } from "bootstrap"

export default class extends Controller {
  static targets = ["dropdown"];
  static values = { url: String };

  mark_all_seen(event) {
    event.preventDefault();

    var fetch_options = {
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    }

    fetch(this.urlValue, fetch_options)
      .then(response => Dropdown.getOrCreateInstance(this.dropdownTarget).hide())
  }
}