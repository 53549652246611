import { Controller } from "@hotwired/stimulus"

// TODO: Replace this controller with Turbo Frames
export default class extends Controller {
  static targets = [ "query", "type", "game", "button", "results" ]
  static values = { url: String }

  initialize() {
    var debounce = require('lodash.debounce');

    this.debouncedSearch = debounce(this.search, 500);
  }

  onSearchKeyup() {
    this.debouncedSearch();
  }

  search() {
    var fetch_options = {
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    }

    fetch(this.urlValue + "?" + new URLSearchParams({
      "filter[query]": this.queryTarget.value,
      "filter[searchable_type]": this.typeTarget.value,
      "filter[game_id]": this.gameTarget.value
    }), fetch_options)
      .then(response => response.text())
      .then(html => this.resultsTarget.innerHTML = html)
  }

  reset() {
    this.resultsTarget.innerHTML = '';
    this.queryTarget.value = '';
  }
}
