import { Controller } from "@hotwired/stimulus";
import Huebee from "huebee";

export default class extends Controller {
  static targets = ["input"];
  static values = {
    defaultColor: String
  }

  inputTargetConnected(input) {
    let hueb = new Huebee(input, {
      notation: "hex",
      customColors: this.customColors
    });

    const color = input.value || this.defaultColorValue;
    hueb.setColor(color);
  }

  disconnect() {
    // Currently, Huebee has no way to destroy itself.
    // https://github.com/metafizzy/huebee/issues/16
  }

  get customColors() {
    return [
      "#000000",
      "#FFFFFF",
      "#F44336",
      "#9C27B0",
      "#673AB7",
      "#3F51B5",
      "#03A9F4",
      "#00BCD4",
      "#009688",
      "#4CAF50",
      "#8BC34A",
      "#CDDC39",
      "#FFEB3B",
      "#FFC107",
      "#FF9800",
      "#FF5722",
      "#795548",
      "#9E9E9E",
      "#607D8B"
    ];
  }
}
