import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sum"];
  static values = {
    min: Number
  };

  increase() {
    this.sumTarget.value = this.currentSum() + 1;
  }

  decrease() {
    if (this.currentSum() == this.minValue) {
      return;
    }

    this.sumTarget.value = this.currentSum() - 1;
  }

  currentSum() {
    return parseInt(this.sumTarget.value);
  }
}
