import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "output", "parent", "buttonUpload", "buttonUploaded", "removeFile"]
  static values = {
    defaultBackground: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAABHNCSVQICAgIfAhkiAAAAA1JREFUCJlj+Pbtx38ACa4D5A98x70AAAAASUVORK5CYII=",
    uploadedClass: "uploaded",
  }

  connect() {
    if(this.outputTarget.src !== this.defaultBackgroundValue) {
      if (this.hasParentTarget) {
        this.parentTarget.classList.add(this.uploadedClassValue)
      }

      this.showUploadedButton()
    }
  }

  upload() {
    let uploaded_file = this.inputTarget.files[0]

    this.outputTarget.src = URL.createObjectURL(uploaded_file)
    if (this.hasParentTarget) {
      this.parentTarget.classList.add(this.uploadedClassValue)
    }
    if (this.hasRemoveFileTarget) {
      this.removeFileTarget.value = 0
    }

    this.showUploadedButton()
  }

  remove() {
    this.outputTarget.src = this.defaultBackgroundValue
    if (this.hasParentTarget) {
      this.parentTarget.classList.remove(this.uploadedClassValue)
    }

    if (this.hasRemoveFileTarget) {
      this.removeFileTarget.value = 1
    }

    this.showUploadButton()
  }

  showUploadedButton() {
    if (this.hasButtonUploadTarget) {
      this.buttonUploadTarget.style.display = "none"
    }
    if (this.hasButtonUploadedTarget) {
      this.buttonUploadedTarget.style.display = "flex"
    }
  }

  showUploadButton() {
    if (this.hasButtonUploadTarget) {
      this.buttonUploadTarget.style.display = "flex"
    }
    if (this.hasButtonUploadedTarget) {
      this.buttonUploadedTarget.style.display = "none"
    }
  }
}
