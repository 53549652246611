import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["trigger", "item", "frame", "globalPicker"];
  static values = {
    formName: String,
    nothingSelectedError: String,
    genericError: String,
    confirmText: String,
    requireConfirmation: { type: Boolean, default: false }
  }

  connect() {
    this.load();

    document.addEventListener("click", this.handlePageChange.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.handlePageChange.bind(this));
  }

  load() {
    this.setupItemsFromParams();
  }

  fire(event) {
    event.preventDefault();

    if (this.anyPageIds.length == 0) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.showNothingSelectedError();

      return false;
    }

    if (this.requireConfirmationValue && !event.target.dataset.massOperationSkipConfirmation) {
      modbox.confirm({
        body: this.confirmTextValue,
        center: true,
        okButton: {
          label: "Ok"
        },
        closeButton: {
          label: "Cancel"
        }
      })
      .then(() => {
        this.actuallyFireEvent(event)
      })
      .catch(() => {})
    } else {
      this.actuallyFireEvent(event);
    }
  }

  actuallyFireEvent(event) {
    if (event.target.tagName == "A") {
      this.fireLink(event);
    } else {
      this.fireForm(event);
    }

    this.clearItems();
  }

  fireLink(event) {
    const triggerHref = event.target.href;
    let method = event.target.dataset.confirmMethod || "GET";

    if (triggerHref) {
      if (method.toLowerCase() != "get") {
        // it's a patch or something so needs to be a form
        var form = document.createElement("form");
        document.body.appendChild(form);
        form.action = this.generateUrl(triggerHref);
        form.method = "POST";

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "_method";
        input.value = event.target.dataset.confirmMethod.toUpperCase();
        form.appendChild(input);

        form.submit();
      } else {
        // just a regular link
        window.location = this.generateUrl(triggerHref);
      }
    }
  }

  fireForm(event) {
    event.preventDefault();

    let form = event.target.closest("form");

    this.anyPageIds.forEach(id => {
      let input = document.createElement("input");
      input.name = this.paramNameForUrl;
      input.value = id;
      input.type = "hidden";
      form.appendChild(input);
    });

    form.submit();
  }

  handlePageChange(event) {
    const target = event.target;

    if (!target) {
      return;
    }

    if (target.classList.contains("page-link")) {
      this.updateNextPageHref(event);
    }
  }

  setupItemsFromParams() {
    const url = new URL(window.location.href);
    const paramsIds = url.searchParams.getAll(this.paramNameForUrl);

    this.splitCurrentPageItemsFromParams(paramsIds);
  }

  splitCurrentPageItemsFromParams(paramsIds) {
    this.anotherPageIds = paramsIds;

    paramsIds.forEach((itemId) => {
      let currentPageItem = this.itemTargets.filter(x => x.value == itemId);
      if (currentPageItem.length) {
        currentPageItem.forEach(item => item.checked = true);
        this.anotherPageIds = this.anotherPageIds.filter(x => x != itemId);
      }
    });
  }

  updateNextPageHref(event) {
    event.target.href = this.generateUrl(event.target.href);
  }

  clearItems() {
    this.itemTargets.forEach(item => item.checked = false);
    this.anotherPageIds = [];
    this.handleCheck();
  }

  checkItems(_event) {
    const checked = this.globalPickerTarget.checked;
    this.itemTargets.forEach(item => item.checked = checked);
    this.handleCheck();
  }

  get anyPageIds() {
    return this.anotherPageIds.concat(this.checkedIds);
  }

  get checkedItems() {
    return this.itemTargets.filter(x => x.checked);
  }

  get paramName() {
    if (this.itemTargets.length == 0) {
      return "";
    }

    return this.itemTargets[0].name.replace("[]", "");
  }

  get paramNameForUrl() {
    return this.formNameValue ? this.formNameValue + `[${this.paramName}][]` : `${this.paramName}[]`;
  }

  get checkedIds() {
    return this.checkedItems.map(item => item.value);
  }

  handleCheck() {
    const url = this.generateUrl(window.location.href);
    window.history.replaceState(null, "", url);
  }

  generateUrl(href) {
    let url = new URL(href);
    url.searchParams.delete(this.paramNameForUrl);
    this.anyPageIds.forEach(id => url.searchParams.append(this.paramNameForUrl, id));

    return url.toString();
  }

  showNothingSelectedError() {
    modbox.alert({
      body: this.nothingSelectedErrorValue,
      center: true
    });
  }

  showGenericError() {
    modbox.alert({
      body: this.genericErrorValue,
      center: true
    });
  }
}
