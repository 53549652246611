import { Controller } from "@hotwired/stimulus"
import { Popover } from "bootstrap"

export default class extends Controller {
  static targets = ["trigger"]
  static values = {
    text: String,
    successText: { type: String, default: "Copied!" }
  }

  connect() {
    var popover = new Popover(
      this.triggerTarget, {
        trigger: "focus",
        placement: "top",
        delay: { hide: 1000 }
      });
  }

  copy(event) {
    event.preventDefault();

    var aux = document.createElement("input");
    aux.setAttribute("value", this.textValue);
    document.body.appendChild(aux);
    aux.select();

    document.execCommand("copy");
    document.body.removeChild(aux);
  }
}
