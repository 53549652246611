import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"];

  get tselect() {
    return this.inputTarget.tomselect;
  }

  clear() {
    this.tselect.clear();
  }

  selectMultipleOptions({ params: { type } }) {
    Array.from(this.inputTarget.options).forEach(option => {
      if (option.dataset.quickMultiSelectType === type) {
        this.tselect.addItem(option.value, true)
      }
    });

    this.inputTarget.dispatchEvent(new Event("change"));
  }
}
