/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context("../images", true)

import "core-js/stable"
import "regenerator-runtime/runtime"
import "controllers"
import "bootstrap";
import Rails from "@rails/ujs";
import "tom-select"
import "bootstrap-icons/font/bootstrap-icons.css"
import * as Turbo from "@hotwired/turbo"
import "@fontsource/roboto/300";
import "@fontsource/roboto/400";
import "@fontsource/roboto/500";
import "@fontsource/roboto/700";
import ApexCharts from "apexcharts";
window.ApexCharts = ApexCharts;
import LocalTime from "local-time"
LocalTime.start()

import * as bootstrap from "bootstrap";
import modbox from "bootstrap-modbox/dist/bootstrap-modbox.esm.min";
modbox.bootstrapModal = bootstrap.Modal;
window.modbox = modbox;

Rails.start();
Turbo.session.drive = false

document.addEventListener("turbo:frame-missing", function(event) {
  event.preventDefault()
  event.detail.visit(event.detail.response)
})

// import css
require("../stylesheets/application.scss")
