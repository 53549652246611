import RichEditorController from "./rich_editor_controller.es6"

export default class extends RichEditorController {
  get froalaInitOptions() {
    let defaultOptions = super.froalaInitOptions;

    const safeFonts = {
      "Arial,Helvetica,sans-serif": "Arial",
      "Georgia,serif": "Georgia",
      "Tahoma,Geneva,sans-serif": "Tahoma",
      "'Times New Roman',Times,serif": "Times New Roman",
      "Verdana,Geneva,sans-serif": "Verdana"
    };

    const defaultColors = [
      "#F05424", "#F37650", "#F59173", "#F7A78F", "#F9B9A5", "#FAC7B7", "#FBD2C5",
      "#0A7DAD", "#3897BD", "#62ACCA", "#81BDD5", "#9ACADD", "#AED5E4", "#BEDDE9",
      "#202021", "#4E4E50", "#626264", "#7A7A7D", "#98989C", "#BEBEC3", "#D5D5D9",
      "#E4E4E7", "#EDEDF4", "#F6F6F9", "#FFFFFF", "#FFAB40", "#FF5252", "#43A047",
      "REMOVE"
    ];

    return Object.assign({}, defaultOptions, {
      linkEditButtons: ["linkOpen", "linkEdit", "linkRemove"],
      quickInsertButtons: ["image", "table", "ul", "ol", "hr"],
      useClasses: false,
      imageOutputSize: true,
      fontSizeUnit: "px",
      fontSizeDefaultSelection: "14",
      fontSizeSelection: true,
      fontFamilySelection: true,
      fontFamily: safeFonts,
      fontSize: ["12", "14", "16", "18", "24", "32"],
      colorsBackground: defaultColors,
      tableColors: defaultColors,
      colorsText: defaultColors,
      events: {
        "table.inserted": function (table) {
          table.cellPadding = "0";
          table.cellSpacing = "0";
        }
      }
    })
  }
}
