import { Controller } from "@hotwired/stimulus";
import { basePlacements } from "@popperjs/core";

export default class extends Controller {
  static targets = ["toggler", "optional"];
  static values = { hideInput: { type: Boolean, default: false } };

  connect() {
    this.change();
  }

  change(event) {
    const togglerValue = this.checkedTogglerValue;

    this.optionalTargets.forEach(input => {
      if (input.dataset.radioOptionalInputToggerValue == togglerValue) {
        this._enableInput(input);

        if (this.hideInputValue) {
          this._showInput(input);
        }
      } else {
        this._disableInput(input)

        if (this.hideInputValue) {
          this._hideInput(input);
        }
      }
    });
  }

  get checkedTogglerValue() {
    const checkedToggler = this.togglerTargets.find(toggler => toggler.checked);
    return checkedToggler.value;
  }

  _enableInput(input) {
    if(input.tomselect) {
      input.tomselect.enable();
    } else {
      input.disabled = false;
    }

    // For checkboxes for example. If we disable checkbox, we need to remember about this hidden field.
    // Otherwise each time the boolean will be set to false!
    this._enableAccompanyingHiddenInput(input.name);
  }

  _disableInput(input) {
    if(input.tomselect) {
      input.tomselect.disable();
    } else {
      input.disabled = true;
    }

    // For checkboxes for example. If we disable checkbox, we need to remember about this hidden field.
    // Otherwise each time the boolean will be set to false!
    this._disableAccompanyingHiddenInput(input.name);
  }

  _showInput(input) {
    input.closest(".radio-optional-input-group").classList.remove("d-none");
  }

  _hideInput(input) {
    input.closest(".radio-optional-input-group").classList.add("d-none");
  }

  _disableAccompanyingHiddenInput(inputName) {
    const hiddenField = document.querySelector(`input[type=hidden][name='${inputName}']`);

    if (hiddenField) {
      hiddenField.disabled = true;
    }
  }

  _enableAccompanyingHiddenInput(inputName) {
    const hiddenField = document.querySelector(`input[type=hidden][name='${inputName}']`);

    if (hiddenField) {
      hiddenField.disabled = false;
    }
  }
}
