import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { template: String }
  static targets = ["parent"]

  remove(event) {
    var wrapper = event.target.closest(".nested-attributes-wrapper")

    if (wrapper.dataset.persisted) {
      let destroyInput = wrapper.querySelector("input[name*='_destroy']");
      destroyInput.value = "1";
      wrapper.style.display = "none";
    } else {
      wrapper.remove();
    }
  }

  add(event) {
    const button = event.currentTarget;
    let parentNode = this.parentTarget;
    let newNode = document.createElement("template");
    newNode.innerHTML = this.templateValue;

    parentNode.append(newNode.content.firstChild);
  }
}
