import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["skip"]
  static values = { url: String }

  fire(event) {
    if (!this.urlValue) {
      return;
    }

    const skip = ["label", "input", "a"]
    let canRedirect = true;

    this.skipTargets.forEach((skipContainer) => {
      if (skipContainer.contains(event.target)) {
        canRedirect = false;
      }
    });

    if (skip.includes(event.target.nodeName.toLowerCase())) {
      canRedirect = false;
    }

    if (canRedirect) {
      window.location = this.urlValue;
    }
  }
}
