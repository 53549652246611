import { Controller } from "@hotwired/stimulus"
import Swiper, { Navigation, Pagination } from 'swiper/swiper-bundle'

export default class extends Controller {
  static targets = ["container", "pagination", "buttonNext", "buttonPrev"]

  connect() {
    this.setupSlider();
  }

  setupSlider() {
    Swiper.use([Navigation, Pagination])

    // eslint-disable-next-line no-unused-vars
    new Swiper(this.containerTarget, {
      slidesPerView: 1,
      spaceBetween: 30,
      navigation: {
        nextEl: this.buttonNextTarget,
        prevEl: this.buttonPrevTarget
      },
      pagination: {
        el: this.paginationTarget,
        clickable: true
      },
      breakpoints: {
        768: {
          slidesPerView: 2
        },
        1200: {
          slidesPerView: 3
        }
      }
    })
  }
}
