import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "body" ]
  static values = {
    url: String,
    autoload: { type: Boolean, default: false },
    autoscroll: { type: Boolean, default: false }
  }

  connect() {
    if (this.autoloadValue) {
      this.load();
    }
  }

  load() {
    var fetch_options = {
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    }

    fetch(this.urlValue, fetch_options)
      .then(response => response.text())
      .then((html) => {
        this.bodyTarget.innerHTML = html;

        if (this.autoscrollValue) {
          let autoScrollId;

          let url = new URL(window.location.href);
          if (url.searchParams.get("mission_id")) {
            autoScrollId = `mission_${url.searchParams.get('mission_id')}`;
          } else if (url.searchParams.get("level_id")) {
            autoScrollId = `level_${url.searchParams.get('level_id')}`;
          }

          let autoScrollElment = document.getElementById(autoScrollId);
          if (autoScrollElment) {
            autoScrollElment.scrollIntoView();
          }
        }
      });
  }
}
