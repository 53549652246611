import { Controller } from "@hotwired/stimulus"
import Tree from "tui-tree"
import Mustache from "mustache"

export default class extends Controller {
  static values = {
    structure: { type: Array, default: [] },
    url: String,
  }

  connect() {
    var that = this;

    var tree = new Tree('#levelTree', {
      data: this.structureValue,
      nodeDefaultState: 'opened',
      template: {
        internalNode:
          '<div class="tui-tree-content-wrapper tui-tree-root-btn notDraggable" data-levelid="{{levelid}}">' +
            '<div class="tui-tree-content-inner notDraggable">' +
              '<span class="tui-tre-handle">' +
                '<i class="bi-list"></i>' +
              '</span>' +
              '<span class="tui-tree-text tui-js-tree-text notDraggable">' +
                '{{text}}' +
              '</span>' +
              '<button type="button" class="tui-tree-toggle-btn tui-js-tree-toggle-btn notDraggable">' +
                '<span class="tui-ico-tree notDraggable"></span>' +
              '</button>' +
            '</div>' +
          '</div>' +
          '<ul class="tui-tree-subtree tui-js-tree-subtree">' +
            '{{{children}}}' +
          '</ul>',
        leafNode:
          '<div class="tui-tree-content-wrapper tui-tree-root-btn notDraggable" data-levelid="{{levelid}}">' +
            '<div class="tui-tree-content-inner notDraggable">' +
              '<span class="tui-tre-handle" >' +
                '<i class="bi-list"></i>' +
              '</span>' +
              '<span class="tui-tree-text tui-js-tree-text notDraggable">' +
                '{{text}}' +
              '</span>' +
            '</div>' +
          '</div>'
      },
      renderTemplate: function(tmpl, props) {
        return Mustache.render(tmpl, props);
      }
    }).enableFeature('Draggable', {
      helperClassName: 'tui-tree-drop',
      lineClassName: 'tui-tree-line',
      rejectedClassNames: ['notDraggable'],
      helperPos: {x: 0, y: -160},
      isSortable: true
    });

    tree.on('move', function(eventData) {
      var newParent = document.getElementById(eventData.newParentId);
      if(newParent) {
        var newWrapper = newParent.getElementsByClassName("tui-tree-content-wrapper")[0];
        var newParentLevelId = newWrapper.dataset.levelid;
      } else {
        var newParentLevelId = null;
      }

      var node = document.getElementById(eventData.nodeId);
      var nodeWrapper = node.getElementsByClassName("tui-tree-content-wrapper")[0];
      var nodeLevelId = nodeWrapper.dataset.levelid;

      console.log(nodeLevelId + " will be moved to parent level " + newParentLevelId);

      const meta_element = document.head.querySelector(`meta[name="csrf-token"]`);
      const csrf_token = meta_element ? meta_element.getAttribute("content") : null;
      const fetch_options = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrf_token
        },
        method: "PATCH",
        body: JSON.stringify({
          level_id: nodeLevelId,
          parent_id: newParentLevelId
        })
      };

      fetch(that.urlValue, fetch_options);
    });
  }
}
