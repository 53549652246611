import { Controller } from "@hotwired/stimulus";
import FroalaEditor from "froala-editor";
import "../js/vendor/froala_plugins.es6";
import Tribute from "tributejs";

export default class extends Controller {
  static targets = ["editor"];
  static values = {
    toolbarButtons: String, // Can't be Object because it's sometimes Array.
    height: { type: Number, default: 450 },
    froalaKey: String,
    embedlyKey: String,
    peopleUrl: String,
    autosaveUrl: String,
    editorClass: String,
    imageUploadBucketName: String,
    imageUploadRegion: String,
    imageUploadKeyStart: String,
    imageUploadFormFields: Object,
    fileUploadBucketName: String,
    fileUploadRegion: String,
    fileUploadKeyStart: String,
    fileUploadFormFields: Object
  };

  connect() {
    this.froalaEditor = new FroalaEditor(this.element, this.froalaInitOptions, () => this.froalaInitEvents())
  }

  disconnect() {
    this.froalaEditor.destroy();
  }

  peopleSearch(text, cb) {
    var fetch_options = {
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    }

    fetch(this.peopleUrlValue, fetch_options)
      .then(response => response.json())
      .then(data => { return cb(data) })
  }

  froalaInitEvents() {
    if (this.element.getAttribute("disabled")) {
      this.froalaEditor.edit.off();
    }

    if (this.hasPeopleUrlValue) {
      this.initPeopleMentions();
    }
  }

  initPeopleMentions() {
    var that = this;

    const tribute = new Tribute({
      values: function(text, cb) {
        that.peopleSearch(text, users => cb(users))
      },
      selectTemplate: function(item) {
        return '<span class="fr-deletable fr-tribute" data-notify="' + item.original.id + '">@' + item.original.key + '</a></span>';
      }
    })

    tribute.attach(this.froalaEditor.el);

    this.froalaEditor.events.on('keydown', function(e) {
      if (e.which == FroalaEditor.KEYCODE.ENTER && tribute.isActive) {
        return false;
      }
    }, true);
  }

  get froalaInitOptions() {
    let imageUploadOptions = {};
    let fileUploadOptions = {};

    if (this.hasImageUploadBucketNameValue) {
      imageUploadOptions = {
        bucket: this.imageUploadBucketNameValue,
        region: `s3-${this.imageUploadRegionValue}`,
        keyStart: this.imageUploadKeyStartValue,
        params: this.imageUploadFormFieldsValue
      };
    }

    if (this.hasFileUploadBucketNameValue) {
      fileUploadOptions = {
        bucket: this.fileUploadBucketNameValue,
        region: `s3-${this.fileUploadRegionValue}`,
        keyStart: this.fileUploadKeyStartValue,
        params: this.fileUploadFormFieldsValue
      };
    }

    const defaultTableStyles = {
      "fr-no-borders": "Hide Borders",
      "fr-dashed-borders": "Dashed Borders",
      "fr-alternate-rows": "Alternate Rows"
    };

    const ctaCustomStyles = {
      "fr-cta-primary": "Outline Primary",
      "fr-cta-secondary": "Outline Secondary",
      "fr-cta-primary-fulfilled": "Fulfilled Primary",
      "fr-cta-secondary-fulfilled": "Fulfilled Secondary",
      "fr-cta-rounded": "Rounded",
    };

    const defaultInlineStyles = {
      "Fulfilled Primary": "background: #F05424; color: #FFFFFF; line-height: 44px; padding: 14px 16px; border-radius: 4px; text-decoration: none;",
      "Outline Primary": "background: transparent; color: #F05424; line-height: 44px; padding: 12px 16px; border: 2px solid #F05424; border-radius: 4px; text-decoration: none;",
      "Fulfilled Primary Rounded": "background: #F05424; color: #FFFFFF; line-height: 44px; padding: 14px 24px; border-radius: 28px; text-decoration: none; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14), 0px 4px 4px rgba(0, 0, 0, 0.12);",
      "Fulfilled Secondary": "background: #0A7DAD; color: #FFFFFF; line-height: 44px; padding: 14px 16px; border-radius: 4px; text-decoration: none;",
      "Outline Secondary": "background: transparent; color: #0A7DAD; line-height: 44px; padding: 12px 16px; border: 2px solid #0A7DAD; border-radius: 4px; text-decoration: none;",
      "Tag Rounded": "background: rgba(10, 125, 173, 0.08); line-height: 32px; color: #0A7DAD; border-radius: 16px; padding: 8px 10px;",
      "Tag Square": "background: #0A7DAD; line-height: 32px; color: #FFFFFF; border-radius: 4px; padding: 8px 10px;"
    };

    return {
      attribution: false,
      key: this.froalaKeyValue,
      embedlyKey: this.embedlyKeyValue,
      height: this.heightValue,
      quickInsertEnabled: false,
      toolbarButtons: JSON.parse(this.toolbarButtonsValue),
      toolbarButtonsXS: {
        moreText: {
          buttons: ["bold", "italic", "underline", "fontFamily", "fontSize", "textColor", "clearFormatting"],
          align: "left",
          buttonsVisible: 1
        },
        moreParagraph: {
          buttons: ["paragraphFormat", "align", "formatOL", "formatUL"],
          align: "left",
          buttonsVisible: 1
        },
        moreRich: {
          buttons: ["emoticons", "insertLink"],
          align: "left",
          buttonsVisible: 2
        },
      },
      linkAlwaysBlank: true,
      editorClass: this.editorClassValue,
      videoUpload: false,
      paragraphFormat: {
        H1: "Heading 1",
        H2: "Heading 2",
        H3: "Heading 3",
        H4: "Heading 4",
        N: "Normal",
      },

      linkStyles: ctaCustomStyles,
      tableStyles: defaultTableStyles,
      inlineStyles: defaultInlineStyles,
      htmlRemoveTags: ["script"],
      imageUploadToS3: imageUploadOptions,
      fileUploadToS3: fileUploadOptions,
      saveInterval: 3000,
      saveParam: this.element.name,
      saveMethod: "POST",
      saveURL: this.autosaveUrlValue,
      toolbarSticky: false,
      pasteAllowLocalImages: false,
      wordDeniedAttrs: ['id', 'class', 'style']
    };
  }
}
