import { Controller } from "@hotwired/stimulus";
import { createConsumer } from "@rails/actioncable";
import { FancyMessage } from "js/utils/fancy_message.es6";

export default class extends Controller {
  connect() {
    this.setupChannel();
  }

  setupChannel() {
    const consumer = createConsumer();

    consumer.subscriptions.create({ channel: "AsyncNotificationChannel" }, {
      received(data) {
        let { type, message } = data;

        if (message) {
          switch (type) {
            case "info": {
              new FancyMessage("info", message).display();
              break;
            }
            case "success": {
              new FancyMessage("success", message).display();
              break;
            }
            case "failure": {
              new FancyMessage("error", message).display();
              break;
            }
          }
        }

        if (data.dom_id_to_remove) {
          let record = document.querySelector(`#${data.dom_id_to_remove}`);

          if (record) {
            record.remove();
          }
        }

      }
    });
  }
}
