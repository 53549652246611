import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String }
  static targets = [ "background" ]

  connect() {
    if (!this.urlValue) {
      return;
    }

    this.backgroundTarget.style.backgroundImage = `url('${this.urlValue}')`;
  }
}
