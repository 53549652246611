import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  initialize() {
    const debounce = require("lodash.debounce");

    this.debouncedSubmit = debounce(this.submit, 500);
  }

  submit(_event) {
    this.formTarget.requestSubmit();
  }
}
