import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    max: Number,
    price: Number,
    credits: Number,
    track: { type: Boolean, default: true },
    backorder: { type: Boolean, default: false }
  }

  static targets = ["input", "total"]

  increase(event) {
    if (this.trackValue && !this.backorderValue) {
      if (this.inputTarget.value >= this.maxValue) {
        return;
      }
    }

    var currentValue = parseInt(this.inputTarget.value);
    var nextValue = currentValue + 1;
    var newTotal = nextValue * this.priceValue;

    if (newTotal > this.creditsValue) {
      return;
    }

    this.inputTarget.value++;
    this.updateTotal();
  }

  decrease(event) {
    if (this.inputTarget.value == 1) {
      return
    }

    this.inputTarget.value--;
    this.updateTotal();
  }

  change(event) {
    var currentValue = parseInt(this.inputTarget.value);
    if (isNaN(currentValue)) {
      return;
    }

    var newTotal = currentValue * this.priceValue;
    if (newTotal > this.creditsValue) {
      this.inputTarget.value = 1;
    } else {
      if (this.trackValue || !this.backorderValue) {
        if (this.inputTarget.value > this.maxValue) {
          this.inputTarget.value = this.maxValue;
        }
      }

      this.updateTotal();
    }
  }

  updateTotal() {
    var newTotal = parseInt(this.inputTarget.value) * this.priceValue;
    this.totalTarget.innerHTML = newTotal;
  }
}
