import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  app = {};
  messaging = {};
  currentToken = "";
  config = {};

  connect() {
    isSupported()
      .then(result => {
        if(!result) {
          return;
        } else {
          fetch("/firebase-config.json")
            .then(response => response.json())
            .then(data => {
              this.config = data;
              this.app = initializeApp(this.config);
              this.messaging = getMessaging(this.app);

              this.askForPermission();
            });
        }
      });
  }

  saveToken() {
    getToken(this.messaging)
      .then((token) => {
        if (token) {
          let url = "/push_notifications/registration_tokens";
          const formData = new FormData();
          formData.append("token", token);

          fetch(url, { method: "POST", body: formData });
        }
      })
      .catch((err) => {
        console.log("Unable to retrieve token ", err);
      });
  }

  askForPermission() {
    Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          this.saveToken();
        } else {
          console.log("Unable to get permission to notify.");
        }
      })
      .catch((err) => {
        console.log("Unable to request permission ", err);
      });
  }
}
