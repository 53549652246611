import { Controller } from "@hotwired/stimulus";
import { map, trim } from "lodash";

export default class extends Controller {
  static targets = ["record"];

  connect() {
    this.element.addEventListener("next_batch_wanted", () => {
      this.loadNextBatch();
    });

    // Initial one.
    this.loadNextBatch();
  }

  disconnect() {
  }

  loadNextBatch() {
    // Stop hitting server;
    if (this.currentlyLoadingNextBatch || this.dry) {
      return;
    }

    this.currentlyLoadingNextBatch = true;

    const params = {
      record_stream: {
        batch_size: this.batchSize,
        loaded_record_ids: this.loadedRecordIDs
      }
    };

    const meta_element = document.head.querySelector(`meta[name="csrf-token"]`);
    const csrf_token = meta_element ? meta_element.getAttribute("content") : null;

    const options = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf_token
      },
      method: "POST",
      body: JSON.stringify(params)  
    };

    let streamSize = 0;
    fetch(this.sourceUrl, options)
      .then(response => {
        streamSize = response.headers.get("X-Record-Stream-Size");
        return response.text();
      })
      .then(html => {
        this.handleNextBatch(html, streamSize);
      })
      .then(() => {
        this.currentlyLoadingNextBatch = false;
      });
  }

  handleNextBatch(html, streamSize) {
    const newNodes = new DOMParser().parseFromString(html, "text/html").body.childNodes;
    
    newNodes.forEach(node => {
      this.element.append(node);
    });

    if (trim(html).length > 0 && !this.responseHasRecords(html)) {
      this.dry = true; // No more data to load.
    }
    this.stream.dispatchEvent(new CustomEvent("new_batch_loaded", { detail: { streamSize: streamSize } }));
  }

  responseHasRecords(html) {
    return html.match("data-record-stream-target='record'");
  }

  get stream() {
    return this.element;
  }

  get loadedRecords() {
    return this.recordTargets;
  }

  get dry() {
    return this.element.dataset.dry;
  }

  set dry(newVal) {
    return this.element.dataset.dry = newVal;
  }

  get loadedRecordIDs() {
    return map(this.loadedRecords, (record) => record.dataset.recordStreamRecordId);
  }

  get batchSize() {
    return parseInt(this.element.dataset.recordStreamBatchSize || 20);
  }

  get sourceUrl() {
    return this.element.dataset.recordStreamSourceUrl;
  }

}
