import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["scopeSelector", "heavyPart"]
  static values = { url: String }

  connect() {
    this.scopeSelectorTarget.addEventListener("change", () => {
      this.loadHeavyPart();
    });

    this.loadHeavyPart();
  }

  disconnect() {
    this.scopeSelectorTarget.removeEventListener("change", () => {
      this.loadHeavyPart();
    });
  }

  loadHeavyPart() {
    if (this._somethingIsSelected()) {
      var fetch_options = {
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      };
      var url_params = new URLSearchParams(new FormData(this.element.closest("form")));

      fetch(this.urlValue + "?" + url_params, fetch_options)
        .then(response => response.text())
        .then(html => this.heavyPartTarget.innerHTML = html)
    } else {
      this.heavyPartTarget.innerHTML = ""
    }
  }

  // Make sure selector val is always an Array. Important for consistency.
  _arrayVal(selector) {
    var castArray = require('lodash.castarray');
    let val = selector.value

    if (val) {
      return castArray(val);
    } else {
      return [];
    }
  }

  _somethingIsSelected() {
    return this._arrayVal(this.scopeSelectorTarget).length > 0
  }
}
