import { Controller } from "@hotwired/stimulus";
import { createConsumer } from "@rails/actioncable";

export default class extends Controller {
  static targets = ["counter"];

  connect() {
    this.setupChannel();
  }

  setupChannel() {
    const consumer = createConsumer();
    const that = this;

    consumer.subscriptions.create({ channel: "Notifications::LiveChannel" }, {
      received(data) {
        if ("unseen_count" in data) {
          that.counterTarget.innerHTML = data.unseen_count;

          if (data.unseen_count > 0) {
            that.counterTarget.classList.remove("invisible");
          } else {
            that.counterTarget.classList.add("invisible");
          }
        }
      }
    });
  }
}
