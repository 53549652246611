import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate"

export default class extends Controller {
  static targets = [ "datepicker", "datetimepicker" ]

  datepickerTargetConnected(elem) {
    flatpickr(elem, {
      allowInput: true,
      altFormat: "m/d/Y",
      altInput: true,
      dateFormat: "Y-m-d",
      enableTime: false,
      shorthandCurrentMonth: true
    })
  }

  datetimepickerTargetConnected(elem) {
    flatpickr(elem, {
      allowInput: true,
      altFormat: 'm/d/Y H:i',
      altInput: true,
      dateFormat: "Y-m-d H:i",
      enableTime: true,
      shorthandCurrentMonth: true,
      time_24hr: true
    })
  }
}
