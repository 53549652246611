import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ["input"];
  static values = {
    url: String
  }

  inputTargetConnected(elem) {
    // TODO: Convert this controller to cover single selectbox instance so that we can reach for configuration
    // values the idiomatic Stimulus way, rather than elem.dataset.
    const url = elem.dataset.optionsUrl;
    const withoutRemoveButton = elem.dataset.withoutRemoveButton;
    const createNew = elem.dataset.javascriptSelectCreateNewValue;

    let options = {
      plugins: ["remove_button"],
      maxOptions: null,
      onInitialize: () => { elem.tomselect.refreshOptions(false) },
      onItemAdd: () => { elem.tomselect.close() },
    };

    if (withoutRemoveButton) {
      options = {
        maxOptions: null,
        allowEmptyOption: true,
        onInitialize: () => { elem.tomselect.refreshOptions(false) },
        onItemAdd: () => { elem.tomselect.close() },
      };
    }

    if (createNew) {
      options = Object.assign({}, options, {
        create: true,
        createOnBlur: true
      })
    }

    if (url) {
      options = Object.assign({}, options, {
        valueField: "id",
        labelField: "text",
        onItemAdd: () => { elem.tomselect.clearOptions(); elem.tomselect.close() },
        shouldLoad: function (query) {
          return query.length > 1;
        },
        load: function (query, callback) {
          let fullUrl = new URL(url);
          fullUrl.searchParams.append("term", query);

          fetch(fullUrl)
            .then(response => response.json())
            .then(json => {
              callback(json.results);
            }).catch(() => {
              callback();
            });
        }
      });
    }

    new TomSelect(elem, options);
  }
}
