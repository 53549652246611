import { Controller } from "@hotwired/stimulus";
import moment from "moment";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = [
    "recurredByControl", "recurredOn", "modalWindow", "repeatOnMonth", "repeatEveryValue", "onDataPickerInput", "afterOccurences",
    "weekDaysInput", "repeatEveryType", "weekDaysSection", "monthSection"
  ]

  initialize() {
    this.checkDefaultValue();
  }

  recurredByControlTargetConnected(select) {
    select.addEventListener("change", (e) => {
      if (e.target.value === "custom") {
        this.toggleModal();

        const day = moment(this.recurredOnTarget.value).format("D");
        const dayInWord = moment(this.recurredOnTarget.value).format("dddd");

        this.repeatOnMonthTarget.options[0].innerText = `Monthly on day ${day}`;
        let dayOfMonth = "First";
        const valueOfDay = Math.round(day / 7);
        switch (valueOfDay) {
          case 1: {
            dayOfMonth = "First";
            break;
          }
          case 2: {
            dayOfMonth = "Second";
            break;
          }
          case 3: {
            dayOfMonth = "Third";
            break;
          }
          case 4: {
            dayOfMonth = "Fourth";
            break;
          }
          case 5: {
            dayOfMonth = "Fifth";
            break;
          }
        }
        this.repeatOnMonthTarget.options[1].innerText = `Monthly on the ${dayOfMonth} ${dayInWord}`;
      }
    });
  }

  repeatEveryTypeTargetConnected(select) {
    select.addEventListener("change", (e) => {
      const resetWeekDays = () =>
        [...document.getElementsByClassName("week_day")].map((item) => {
          item.value = "";
          item.checked = false;
        });

      if (e.target.value !== "week") {
        resetWeekDays();
      }

      if (e.target.value === "week") {
        const day = moment(this.recurredOnTarget.value)
          .format("dddd")
          .toLowerCase();
        const option = [...document.getElementsByClassName("week_day")].find(
          (item) => item.id.includes(day)
        );
        option.value = day;
        option.checked = true;
        this.weekDaysSectionTarget.classList.remove("d-none");
      } else {
        this.weekDaysSectionTarget.classList.add("d-none");
      }

      if (e.target.value === "month") {
        this.monthSectionTarget.classList.remove("d-none");
      } else {
        this.monthSectionTarget.classList.add("d-none");
      }
    });
  }

  checkDefaultValue() {
    const repeatEveryType = document.getElementById("repeat_every_type");

    if (repeatEveryType.value === "week") {
      if (
        typeof [...document.getElementsByClassName("week_day")].find(
          (item) => item.checked
        ) === "undefined"
      ) {
        const day = moment(this.recurredOnTarget.value)
          .format("dddd")
          .toLowerCase();
        const option = [...document.getElementsByClassName("week_day")].find(
          (item) => item.id.includes(day)
        );
        option.value = day;
        option.checked = true;
      }
      this.weekDaysSectionTarget.classList.remove("d-none");

    } else if (repeatEveryType.value === "month") {
      this.monthSectionTarget.classList.remove("d-none");
    }

    if(this.recurredByControlTarget.dataset.selected === 'custom_option') {
      this.generateCustomOption();
    }
  }

  generateCustomOption() {
    const pluralize = this.repeatEveryValueTarget.value > 1 ? "s" : "";
    const repeatEveryType = document.getElementById("repeat_every_type");
    let customOption = `Every ${this.repeatEveryValueTarget.value} ${
      repeatEveryType.value
    }${pluralize} `;

    if (repeatEveryType.value === "week") {
      customOption += "on ";
      const filterElement = [
        ...document.getElementsByClassName("week_day"),
      ].filter((item) => item.checked);
      customOption += filterElement
        .map((item) => item.id.split("week_day_")[1])
        .join(", ");
    } else if (repeatEveryType.value === "month") {
      const repeatOnMonthSelected = [...this.repeatOnMonthTarget.options].find(
        (item) => item.selected
      ).innerText;
      if (this.repeatEveryValueTarget.value > 1) {
        customOption += `${repeatOnMonthSelected.split("Monthly")[1]}`;
      } else {
        customOption = `${repeatOnMonthSelected}`;
      }
    }

    if (!this.onDataPickerInputTarget.disabled) {
      customOption += `, until ${this.onDataPickerInputTarget.value} `;
    } else if (!this.afterOccurencesTarget.disabled) {
      customOption += `, ${this.afterOccurencesTarget.value} times`;
    }

    const customElement = [...this.recurredByControlTarget.options].find(
      (item) => item.value === "custom_option"
    );

    if (typeof customElement !== "undefined") {
      customElement.remove();
    }

    const option = document.createElement("option");
    option.value = "custom_option";
    option.innerText = customOption;
    this.recurredByControlTarget.insertBefore(
      option,
      this.recurredByControlTarget.options[this.recurredByControlTarget.options.length - 1]
    );

    this.recurredByControlTarget.value = "custom_option";
  }

  handleRepeatEveryValueChange(e) {
    const repeatEveryType = document.getElementById("repeat_every_type");
    if (e.target.value > 1) {
      [...repeatEveryType.options].map((item) => {
        if (item.innerText === item.value) {
          item.innerText += "s";
        }
      });
    } else {
      [...repeatEveryType.options].map((item) => (item.innerText = item.value));
    }
  }

  handleEndingChange(e) {
    if (e.target.value === "on") {
      this.onDataPickerInputTarget.disabled = false;
      this.onDataPickerInputTarget.value = moment().format("YYYY-MM-DD HH:mm");
    } else {
      this.onDataPickerInputTarget.disabled = true;
      this.onDataPickerInputTarget.value = "";
    }

    this.afterOccurencesTarget.disabled = e.target.value !== "after";
  }

  handleModalCancel() {
    const customElement = [...this.recurredByControlTarget.options].find(
      (item) => item.value === "custom_option"
    );

    if (typeof customElement === "undefined") {
      this.recurredByControlTarget.value = ""
    } else {
      this.recurredByControlTarget.value = "custom_option"
    }

    this.toggleModal();
  }

  handleModalDone() {
    this.generateCustomOption();
    this.toggleModal();
  }

  toggleModal() {
    let modal = Modal.getOrCreateInstance(this.modalWindowTarget);
    modal.toggle();
  }
}
