import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link"];
  static values = {
    key: String,
  };

  linkTargetConnected(link) {
    this.fetchEmbedly(link);
  }

  async fetchEmbedly(link) {
    fetch(`https://api.embedly.com/1/oembed?key=${this.keyValue}&url=${link.href}`)
      .then(async (response) => {
        if(response.ok) {
          link.classList.add("success");
          const json = await response.json();

          let contentContainer
          if(json.title || json.description || json.thumbnail_url) {
            await this.createContent(link, "div", "content", "");
            contentContainer = link.querySelector(".content");
          }

          json.title !== "" && json.title !== undefined
            ? await this.createContent(contentContainer, "h1", "title", json.title)
            : null;
          json.description !== "" && json.description !== undefined
            ? await this.createContent(contentContainer, "p", "description", json.description)
            : null;
          json.thumbnail_url !== "" && json.thumbnail_url !== undefined
            ? await this.createContent(contentContainer, "img", "thumbnail", json.thumbnail_url)
            : null;
        } else {
          link.classList.add("warning");
        }
      }).catch(() => {
        link.classList.add("warning");
      });
  }

  async createContent(parent, tag, className, content) {
    const element = document.createElement(tag);
    element.classList.add(className);

    if(tag === "img") {
      element.setAttribute("src", content);
    } else {
      element.innerText = content;
    }

    parent.appendChild(element);
  }
}
