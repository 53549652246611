import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["progressbar"]

  progressbarTargetConnected(elem) {
    const progressBarActiveLine = elem.querySelector('.progress-bar_line-active')
    const progressBarToolTip = elem.querySelector('.progress-bar_points')
    let progress = this.calculateProgress(elem);

    progressBarActiveLine.style.width = `${progress}%`

    if(progress == 100) {
      progressBarActiveLine.classList.add("completed")
    }

    if (progressBarToolTip) {
      progressBarToolTip.style.left = `calc(${progress}% - 110px)`
      const leftstyle = getComputedStyle(progressBarToolTip).left
      const leftint = parseInt(leftstyle)

      if (isNaN(leftint) || leftint < 0) {
        progressBarToolTip.style.left = 0
      }
    }
  }

  calculateProgress(elem) {
    const progressBarPercentage = parseFloat(elem.dataset.percentage)
    const progressBarValue = parseInt(elem.dataset.value)
    const progressBarMax = parseInt(elem.dataset.max)

    // Value to be displayed was provided:
    if (isFinite(progressBarPercentage)) {
      return Math.min(100, progressBarPercentage);
    }

    if (!isFinite(progressBarMax) || progressBarMax <= 0) {
      return 0;
    } else {
      let progress = (progressBarValue / progressBarMax) * 100;

      // Never more than 100% to avoid messing layouts:
      return Math.min(100, progress)
    }
  }
}
