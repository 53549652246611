import { Controller } from "@hotwired/stimulus";
import { createConsumer } from "@rails/actioncable";

export default class extends Controller {
  static targets = ["body"];
  static values = {
    active: { type: Boolean, default: false },
    url: String,
    gameId: Number
  };

  activate() {
    if (this.activeValue == true) {
      return;
    }

    this.activeValue = true;
    this.load();
    this.setupChannel();
  }

  connect() {
    if (this.activeValue) {
      this.load();
      this.setupChannel();
    }
  }

  setupChannel() {
    const consumer = createConsumer();
    const gameId = this.gameIdValue == 0 ? null : this.gameIdValue;
    const that = this;

    consumer.subscriptions.create({ channel: "LocalFeed::CableChannel", game_id: gameId }, {
      received(data) {
        that.load();
      }
    });
  }

  load() {
    const fetchOptions = {
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    };

    fetch(this.urlValue, fetchOptions)
      .then(response => response.text())
      .then(html => this.bodyTarget.innerHTML = html);
  }
}
