import ApexCharts from "apexcharts"
import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["chart"]
  static values = {
    title: String,
    height: Number,
    subtitle: Array,
    color: String,
    colorScaleMin: Number,
    colorScaleMax: Number,
    series: Array
  }

  connect() {
    const chart = new ApexCharts(this.chartTarget, {
      series: this.seriesValue,
      chart: {
        height: this.heightValue,
        type: 'heatmap'
      },
      xaxis: {
        labels: {
          rotate: -90,
          rotateAlways: true
        },
        tooltip: {
          enabled: false
        }
      },
      plotOptions: {
        heatmap: {
          radius: 3,
          colorScale: {
            min: this.colorScaleMinValue,
            max: this.colorScaleMaxValue
          }
        }
      },
      colors: [this.colorValue],
      dataLabels: {
        enabled: false
      },
      title: {
        text: this.titleValue,
        align: 'center',
      },
      subtitle: {
        text: this.subtitleValue,
        align: 'center',
        offsetY: 20
      },
      noData: {
        text: "No data",
      },
    })

    chart.render()
  }
}
