import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggler", "optional"];

  connect() {
    this.change();
  }

  change(event) {
    if(this.togglerTarget.checked) {
      this.optionalTargets.forEach(input => this._enableInput(input));
    } else {
      this.optionalTargets.forEach(input => this._disableInput(input));
    }
  }

  _enableInput(input) {
    if(input.tomselect) {
      input.tomselect.enable();
    } else {
      input.disabled = false;
    }

    // For checkboxes for example. If we disable checkbox, we need to remember about this hidden field.
    // Otherwise each time the boolean will be set to false!
    this._enableAccompanyingHiddenInput(input.name);
  }

  _disableInput(input) {
    if(input.tomselect) {
      input.tomselect.disable();
    } else {
      input.disabled = true;
    }

    // For checkboxes for example. If we disable checkbox, we need to remember about this hidden field.
    // Otherwise each time the boolean will be set to false!
    this._disableAccompanyingHiddenInput(input.name);
  }

  _disableAccompanyingHiddenInput(inputName) {
    const hiddenField = document.querySelector(`input[type=hidden][name='${inputName}']`);

    if (hiddenField) {
      hiddenField.disabled = true;
    }
  }

  _enableAccompanyingHiddenInput(inputName) {
    const hiddenField = document.querySelector(`input[type=hidden][name='${inputName}']`);

    if (hiddenField) {
      hiddenField.disabled = false;
    }
  }
}
