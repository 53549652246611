import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { template: String }

  connect() {
    this.lastIndex = 1000;
  }

  remove(event) {
    let section = event.currentTarget.closest(".section_card");
    if (section.dataset.persisted) {
      let destroyInput = section.querySelector("input[name*='_destroy']");
      destroyInput.value = "1";
      section.style.display = "none";
    } else {
      section.remove();
    }
  }

  add(event) {
    const button = event.currentTarget;
    let parentEvidences = document.querySelector(".evidences");
    let newEvidence = document.createElement("template");
    newEvidence.innerHTML = this.templateValue;

    parentEvidences.append(newEvidence.content.firstChild);

    let newEvidenceEl = parentEvidences.querySelector(".section_card:last-child");

    this.setCorrectEvidenceValues(newEvidenceEl, "active", button.dataset.evidenceType);
    this.setCorrectEvidenceValues(newEvidenceEl, "approval_criteria", button.dataset.evidenceType);
    this.setCorrectEvidenceValues(newEvidenceEl, "default_suspension_responses", button.dataset.evidenceType);
    this.setCorrectDestroyValue(newEvidenceEl, button.dataset.evidenceType);

    newEvidenceEl.className = newEvidenceEl.className.replace(/evidence_request_text/, button.dataset.containerClass);

    newEvidenceEl.querySelector(".title").innerText = button.dataset.label;
    this.lastIndex++;
  }

  // since the template used 'texts' by default, change values to the relevant ones;
  setCorrectEvidenceValues(evidenceEl, field, evidenceType) {
    const nameBase = `activity[${evidenceType}_attributes][${this.lastIndex}]`;
    const idBase = `activity_${evidenceType}_attributes_${this.lastIndex}`;

    let el = evidenceEl.querySelector(`.activity_evidence_request_texts_${field}`);
    let input = el.querySelector("input:not([type='hidden'])") || el.querySelector("textarea");
    let labels = el.querySelectorAll("label");
    let hiddenInput = el.querySelector("input[type='hidden']");

    input.setAttribute("name", nameBase + `[${field}]`);
    input.setAttribute("id", idBase + `_${field}`);

    labels.forEach((label) => {
      label.setAttribute("for", input.id);
    });

    if (hiddenInput) {
      hiddenInput.setAttribute("name", input.name);
    }

    el.className = el.className.replace(/evidence_request_texts/, evidenceType);
  }

  setCorrectDestroyValue(evidenceEl, evidenceType) {
    const nameBase = `activity[${evidenceType}_attributes][${this.lastIndex}]`;
    const idBase = `activity_${evidenceType}_attributes_${this.lastIndex}`;

    let input = evidenceEl.querySelector("input[name*='_destroy']");

    input.setAttribute("name", nameBase + `[_destroy]`);
    input.setAttribute("id", idBase + `_destroy`);

    input.className = input.className.replace(/evidence_request_texts/, evidenceType);
  }
}
