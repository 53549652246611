import { Controller } from "@hotwired/stimulus"
import { Toast } from "bootstrap"

export default class extends Controller {
  static targets = ["message", "toastTemplate", "toastContainer"]

  static add(type, message, delay = 5000) {
    const flashes = document.querySelector("[data-controller=flashes]");

    let newMessage = document.createElement('div');
    newMessage.className = "d-none";
    newMessage.dataset.type = type;
    newMessage.dataset.message = message;
    newMessage.dataset.delay = delay;
    newMessage.dataset.flashesTarget = "message";

    flashes.appendChild(newMessage);
  }

  messageTargetConnected(element) {
    const type = element.dataset.type;
    const message = element.dataset.message;
    const delay = Number.parseInt(element.dataset.delay);

    this.showToast(type, message, delay);
  }

  showToast(type, message, delay) {
    const toastClass = this.toastClass(type);
    let templateCopy = this.toastTemplateTarget.content.cloneNode(true);

    templateCopy.querySelector('.toast').classList.add(toastClass);
    templateCopy.querySelector('.toast-body').innerHTML = this.htmlDecode(message);
    this.toastContainerTarget.appendChild(templateCopy);

    let toast = new Toast(this.toastContainerTarget.lastElementChild, { delay: delay });
    toast.show();
  }

  toastClass(type) {
    const mappings = {
      "info": "bg-info",
      "notice": "bg-warning",
      "success": "bg-success",
      "error": "bg-danger",
      "alert": "bg-danger",
      "recaptcha_error": "bg-danger"
    }

    return mappings[type];
  }

  // https://javascript.plainenglish.io/how-to-unescape-html-entities-in-javascript-593eb656bffe
  htmlDecode(input) {
    const e = document.createElement('div');
    e.innerHTML = input;

    return e.innerText;
  }
}
