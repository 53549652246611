import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["keyInput", "button", "error", "spinner"]
  static values = {
    maxSize: Number,
    url: String,
    formFields: String,
    allowedExtensions: Array
  }

  fileSelected() {
    const file = event.target.files[0];

    if (this.fileValid(file)) {
      this.uploadFile(file);
    }
  }

  fileValid(file) {
    this.resetErrors();

    if (file.size > this.maxSizeValue) {
      this.displayError("File is too large");
      this.displayFileName(file);
      return false;
    }

    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!this.allowedExtensionsValue.includes(fileExtension)) {
      this.displayError("Wrong file format");
      this.displayFileName(file);
      return false;
    }

    return true;
  }

  uploadFile(file) {
    const formData = this.buildForm(file);
    const xhr = new XMLHttpRequest();

    xhr.open("post", this.urlValue, true);
    xhr.addEventListener("loadstart", this.transferStarted.bind(this, file));
    xhr.addEventListener("load", this.transferComplete.bind(this, file));
    xhr.addEventListener("error", this.transferFailed.bind(this, file));
    xhr.addEventListener("loadend", this.transferEnded.bind(this, file));
    xhr.send(formData);
  }

  buildForm(file) {
    const formFields = JSON.parse(this.formFieldsValue);
    const formData = new FormData();

    Object.entries(formFields).map(([key, value]) => {
      formData.append(key, value);
    });

    const cleanedName = file.name.replace(/\s/g, "_").replace(/[^\w.-]/gi, "");
    formData.set("key", formFields["key"].replace("${filename}", cleanedName));

    formData.append("Content-Type", file.type);
    formData.append("X-Requested-With", "xhr");
    formData.append("file", file, cleanedName);

    return formData;
  }

  transferComplete(file, data) {
    const s3KeyTarget = this.keyInputTarget;
    const s3Key = data.target.responseXML.querySelector("Key");
    const errorMessage = data.target.responseXML.querySelector("Error Message");

    if (errorMessage) {
      if (this.isTestEnvironment) {
        console.error(data.target.responseXML);
      }

      this.displayError(`Upload failed, please try again (${errorMessage.textContent})`);
    } else {
      s3KeyTarget.value = s3Key.textContent;

      this.displayFileName(file);
    }
  }

  transferFailed(file, data) {
    this.displayError("Upload failed, please try again");
  }

  transferStarted() {
    this.spinnerTarget.style.display = "flex";
    this.buttonTarget.style.display = "none";
  }

  transferEnded() {
    this.spinnerTarget.style.display = "none";
    this.buttonTarget.style.display = "flex";
  }

  resetErrors() {
    this.element.classList.remove("error");
    this.errorTarget.innerText = "";
  }

  displayError(text) {
    this.element.classList.add("error");
    this.errorTarget.innerText = text;
  }

  displayFileName(file) {
    if (!file) {
      return;
    }

    if (this.element.classList.contains("uploaded")) {
      this.element.querySelector(".game-editor-form_uploaded-file p").innerHTML = file.name;
    } else {
      const filePreview = document.createElement("div");

      filePreview.classList.add("game-editor-form_uploaded-file", "validated-form");
      filePreview.innerHTML = `<p>${file.name}</p>`;

      this.buttonTarget.before(filePreview);
    }

    this.element.classList.add("uploaded");
    this.buttonTarget.innerHTML = `change file`;
  }

  get isTestEnvironment() {
    return document.head.querySelector("meta[name=rails-env]").content === "test"
  }
};
