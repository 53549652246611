import { Controller } from "@hotwired/stimulus"
import { Tab } from "bootstrap"

export default class extends Controller {
  connect() {
    var hash = window.location.hash;
    var search = window.location.search;

    if (hash) {
      this.change_to_hash_tab(hash)
    } else if(search) {
      this.change_to_forum_tab()
    } else {
      return
    }
  }

  change_to_hash_tab(hash) {
    var tab_trigger = document.querySelector("button[data-bs-target='"+hash+"']");
    if (!tab_trigger) {
      return
    }

    this.show_tab(tab_trigger);
  }

  change_to_forum_tab() {
    var tab_trigger = document.querySelector("button[data-bs-target='#nav_forum']");
    if (!tab_trigger) {
      return
    }

    this.show_tab(tab_trigger);
  }

  show_tab(trigger) {
    var tab_pane = Tab.getOrCreateInstance(trigger);
    if (!tab_pane) {
      return
    }

    tab_pane.show();
  }
}
