import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    options: Object
  };
  static targets = ["select", "checkbox"];

  connect() {
    if(this.hasSelectTarget) {
      this.select();
    } else {
      var first_variant = this.checkboxTargets[0];
      first_variant.click();
    }
  }

  show(event) {
    var id = "#rewards_buyable_" + event.target.dataset.variant;
    var variant = document.querySelector(id);

    document.querySelectorAll(".variant_form").forEach(node => {
      node.classList.add("d-none");
    });

    variant.classList.remove("d-none");
  }

  select(event) {
    var isEqual = require('lodash.isequal');

    let all_options = Object.entries(this.optionsValue);
    let selected_options = {};

    this.selectTargets
      .forEach((opt) => {
        if(opt.value != "") {
          selected_options[opt.dataset.optiontype] = opt.value
        }
      });

    var selected = [];
    var selected_buyable = all_options.forEach((item) => {
      item.forEach((val) => {
        if(isEqual(val, selected_options)) {
          selected = item;
        }
      });
    });

    var id = "#rewards_buyable_" + selected[0];
    var variant = document.querySelector(id);

    document.querySelectorAll(".variant_form").forEach(node => {
      node.classList.add("d-none");
    });

    if(variant) {
      variant.classList.remove("d-none");
    } else {
      document.querySelector("#unavailable_variant_combination").classList.remove("d-none");
    }
  }
}
