import FlashesController from "controllers/flashes_controller.es6";

// TODO: Does it make sense to keep this class on a new UI?
export class FancyMessage {
  constructor(type, message, delay = 10000) {
    this.type = type;
    this.message = message;
    this.delay = delay;
  }

  display() {
    FlashesController.add(this.type, this.message, this.delay);
  }
}
