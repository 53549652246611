import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["activity", "mission", "level"];
  static values = {
    nothingSelectedError: String,
    genericError: String,
    levelLoad: Number,
    scrollToId: String
  }

  connect() {
    const collapseLevelButton = document.querySelector(`#level_heading_${this.levelLoadValue} .list-button`);

    if (collapseLevelButton) {
      this.levelCollapseToggle(collapseLevelButton, null, this.autoScroll.bind(this));
    }
  }

  checkItems(items, checked) {
    items.forEach((item) => {
      item.checked = checked;
    });
  }

  handleLevelCollapserClick(event) {
    this.levelCollapseToggle(event.currentTarget, event.target);
  }

  levelCollapseToggle(currentTarget, target, callback) {
    target = target || currentTarget;
    if (!target.closest("[data-prevent-collapse]")) {
      const collapse = document.querySelector(`#${currentTarget.dataset.collapseId}`);
      this.loadMissions(collapse, false, callback);
    }
  }

  missionsAlreadyLoaded(missionsBody) {
    if (missionsBody.querySelector(".list-item.mission")) {
      return true;
    } else {
      return false;
    }
  }

  loadMissions(collapse, checked = false, callback) {
    const missionsBody = collapse.querySelector(".list-body");

    if (this.missionsAlreadyLoaded(missionsBody)) {
      return;
    } else {
      const url = collapse.dataset.missionsUrl;
      this.load(url, missionsBody, () => {
        this.checkItems(missionsBody.querySelectorAll("input[data-game-contents-target~='mission']"), checked);
        this.checkItems(missionsBody.querySelectorAll("input[data-game-contents-target~='activity']"), checked);

        if (callback) {
          callback();
        }
      });
    }
  }

  autoScroll() {
    if (this.scrollToIdValue) {
      document.getElementById(this.scrollToIdValue).scrollIntoView();
    }
  }

  fire(event) {
    this.fillForm(event);
  }

  fillForm(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (this.checkedActivities.length > 0 || this.checkedMissions.length || this.checkedLevels.length > 0) {
      let form = event.target.closest("form");

      [...this.checkedActivities, ...this.checkedMissions, ...this.checkedLevels].forEach((item) => {
        let input = document.createElement("input");
        input.name = item.name + "[]";
        input.value = item.value;
        input.type = "hidden";
        form.appendChild(input);
      });

      form.submit();
    } else {
      this.showNothingSelectedError();
    }
  }

  levelToggle(event) {
    const block = document.getElementById(event.target.dataset.togglable);
    const checked = event.target.checked;

    this.checkItems(block.querySelectorAll("input[data-game-contents-target~='mission']"), checked);
    this.checkItems(block.querySelectorAll("input[data-game-contents-target~='activity']"), checked);

    this.loadMissions(block, checked);
  }

  missionToggle(event) {
    const block = document.getElementById(event.target.dataset.togglable);
    const checked = event.target.checked;

    this.checkItems(block.querySelectorAll("input[data-game-contents-target~='activity']"), checked);
  }

  get checkedActivities() {
    return this.activityTargets.filter((el) => el.checked);
  }

  get checkedMissions() {
    return this.missionTargets.filter((el) => el.checked);
  }

  get checkedLevels() {
    return this.levelTargets.filter((el) => el.checked);
  }

  showNothingSelectedError() {
    modbox.alert({
      body: this.nothingSelectedErrorValue,
      center: true
    });
  }

  showGenericError() {
    modbox.alert({
      body: this.genericErrorValue,
      center: true
    });
  }

  load(url, target, callback) {
    const fetchOptions = {
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      }
    };

    fetch(url, fetchOptions)
      .then(response => response.text())
      .then(html => target.innerHTML = html)
      .then(() => callback());
  }
}
